@use '@/styles/utils/mixins.scss' as *;

.bannerImageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 1.25rem;
  width: 100%;
  height: 40rem;

  @include mob() {
    height: 29.25rem;
    margin: 0 1.875rem;
    width: calc(100% - 3.75rem);
  }
}

.bannerImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.1s ease;
}

.bannerImage {
  object-fit: cover;
}
